import React from 'react'
import { Link, graphql } from 'gatsby'
import { Layout } from '../components/layout'
import { AboutPageQuery } from 'types/graphql-types'

interface PageProps {
  data: AboutPageQuery
}

const About: React.FC<PageProps> = ({ data }) => (
  <div>
    <Layout>
      <h1>About {data.site?.siteMetadata?.title ?? '(無題)'}</h1>
      <p>GatsbyJSでできたブログやよ〜</p>
      <Link to="/">Home</Link>
    </Layout>
  </div>
)

export default About;

export const query = graphql`
  query AboutPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
