import React from 'react'
import { Header } from './header'
import { Footer } from './footer'


export const Layout: React.FC = ({children}) => (
  <div className="wrapper">
    <Header />
    <div className="content">{ children }</div>
    <Footer />
  </div>
)